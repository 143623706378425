<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet id="main" ref="main" class="overflow-hidden">
      <NavBar tittle="label.lbl_billingContractUnitPriceRegistration" />
      <!-- 請求契約単価登録画面 -->
      <v-form>
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-row-exeBtn">
              <!-- 取引先-->
              <div class="search-autocomplete first-search-item" style="float: left; width: 450px">
                <v-autocomplete
                  dense
                  v-model="suppliersSelected"
                  :items="supplierList"
                  :label="$t('label.lbl_supplier')"
                  :hint="setSrhSupplier()"
                  class="txt-single"
                  persistent-hint
                  :rules="[rules.required]"
                  :readonly="true"
                ></v-autocomplete>
              </div>

              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 請求No(業務No) -->
              <div class="serch-textbox-arrivalNo" style="float: left; width: 450px">
                <v-autocomplete
                  dense
                  v-model="billingBussinessNoSelected"
                  :items="billingBussinessNoList"
                  :label="$t('label.lbl_billingBussinessNo')"
                  :hint="setBillingBussinessNo()"
                  class="txt-single"
                  persistent-hint
                  :rules="[rules.required]"
                  :readonly="true"
                ></v-autocomplete>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
            </div>
          </v-row>

          <!--ボタン領域-->
          <v-row style="margin-left: 3px">
            <div class="search-row-exeBtn">
              <!-- 請求内容 -->
              <div style="float: left">
                <!-- 戻るボタン-->
                <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_back')">
                  {{ $t("btn.btn_back") }}
                </v-btn>
              </div>
              <div style="float: right">
                <!-- 行追加ボタン-->
                <v-btn color="primary" class="api-btn" @click="addItem()">
                  {{ $t("btn.btn_rowsAdd") }}
                </v-btn>
                <!-- 完了ボタン -->
                <v-btn
                  color="primary"
                  id="btn-content-confirm"
                  class="other-btn"
                  @click="viewConfirmDialog('btn_complete')"
                  >{{ $t("btn.btn_complete") }}</v-btn
                >
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <v-form ref="editedList" lazy-validation>
        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            ref="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-pagination
            disable-sort
            :hide-default-footer="true"
            height="740px"
          >
            <!-- 項目/項目内容 -->
            <template v-slot:[`item.billingItems`]="{ item, index }">
              <div id="table-billingItems">
                <v-autocomplete
                  v-if="item.isNewCreation"
                  v-model="item.billingItems"
                  :items="billingItemList"
                  :hint="checkBillingItems(item).name"
                  :rules="[!item.billingItems || rules.required]"
                  item-text="value"
                  persistent-hint
                  dense
                  :disabled="!item.isNewCreation"
                  @change="changeBillingItem(item)"
                  :error-messages="item.duplicateBillingItemMsg"
                  :ref="'billingItems' + index"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
                <div class="align-left" v-else>{{ getBillingItemsText(item) }}</div>
              </div>
            </template>
            <!-- 科目/科目内容 -->
            <template v-slot:[`item.billingSubject`]="{ item, index }">
              <div did="table-billingSubject">
                <v-autocomplete
                  v-if="item.isNewCreation"
                  v-model="item.billingSubject"
                  :items="filteringSubjectList(item.billingItems)"
                  :rules="[!item.billingItems || rules.required]"
                  persistent-hint
                  dense
                  :disabled="!item.isNewCreation"
                  class="align-left"
                  @change="inputMasterData(item)"
                  :ref="'billingSubject' + index"
                  :error-messages="item.duplicateBillingItemMsg"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
                <div class="align-left" v-else>{{ getBillingSubjectText(item) }}</div>
              </div>
            </template>
            <!-- 単価分類 -->
            <template v-slot:[`item.unitPriceClassificPrice`]="{ item, index }">
              <div id="table-unitPriceClassificPrice">
                <v-autocomplete
                  v-model="item.unitPriceClassificPrice"
                  :items="filteringUnitPriceClassificPriceList(item)"
                  :hint="checkUnitPriceClassificPriceList(item)"
                  item-text="value"
                  :rules="[!item.billingItems || rules.required]"
                  persistent-hint
                  dense
                  class="cursorProcd"
                  :ref="'unitPriceClassificPrice' + index"
                  :error-messages="item.duplicateBillingItemMsg"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>
            <!-- 単価種類 -->
            <template v-slot:[`item.unitPriceType`]="{ item, index }">
              <div id="table-unitPriceType">
                <v-autocomplete
                  v-model="item.unitPriceType"
                  :items="unitPriceTypeList"
                  :hint="checkUnitPriceTypeList(item)"
                  item-text="value"
                  persistent-hint
                  dense
                  class="cursorProcd"
                  :ref="'unitPriceType' + index"
                  :error-messages="item.duplicateBillingItemMsg"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>
            <!-- 単価内容 -->
            <template v-slot:[`item.unitPriceClassContents`]="{ item, value, index }">
              <v-text-field
                :value="value"
                outlined
                dense
                :rules="[
                  !item.billingItems || rules.required,
                  !item.billingItems ||
                    rules.isValidUnitPriceClassContents(
                      value.match(/^[^\x01-\x7E\uFF61-\uFF9F]+$/)
                    ),
                  rules.maxLength22,
                ]"
                class="text-box"
                maxLength="22"
                @load="value = item.unitPriceClassContents"
                @change="changeUnitPriceClassContentsTxt(item, $event)"
                :ref="'unitPriceClassContents' + index"
                :error-messages="item.duplicateBillingItemMsg"
              >
              </v-text-field>
            </template>
            <!-- 単価 -->
            <template v-slot:[`item.unitPrice`]="{ item, index, value }">
              <v-text-field
                :value="value"
                outlined
                dense
                :rules="[
                  !item.billingItems || rules.required,
                  !item.billingItems || rules.isNumber,
                  !item.billingItems || rules.isZero,
                  !item.billingItems || rules.price,
                  (input) => {
                    let result =
                      !item.billingItems ||
                      checkMaxAmount(item.achieveQuantity, input, item.convertedValue);
                    return result;
                  },
                ]"
                class="input-number"
                @change="changeUnitPriceTxt(item, $event, index)"
                @load="value = item.unitPrice"
                maxLength="10"
                :ref="'unitPrice' + index"
              >
              </v-text-field>
            </template>
            <!-- 単価単位 -->
            <template v-slot:[`item.unitPriceUnit`]="{ item, index }">
              <div id="table-unitPriceUnit">
                <v-autocomplete
                  v-model="item.unitPriceUnit"
                  :items="unitPriceUnitList"
                  :hint="checkUnitPriceUnitList(item)"
                  :rules="[!item.billingItems || rules.required]"
                  persistent-hint
                  dense
                  class="cursorProcd"
                  :ref="'cursorProcd' + index"
                  item-text="value"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>
            <!-- 固定数量 -->
            <template v-slot:[`item.achieveQuantity`]="{ item, index, value }">
              <v-text-field
                :value="value"
                outlined
                dense
                :rules="[
                  !(item.billingItems && item.calculationClassification == '01') || rules.required,
                  !item.billingItems || rules.isNumber,
                  !(item.billingItems && item.calculationClassification == '01') || rules.isZero,
                  !(item.billingItems && item.calculationClassification == '01') ||
                    rules.checkQuantity,
                  (input) => {
                    let result =
                      !(item.billingItems && item.calculationClassification == '01') ||
                      checkMaxAmount(input, item.unitPrice, item.convertedValue);
                    return result;
                  },
                ]"
                class="input-number"
                :disabled="item.calculationClassification != '01'"
                maxLength="6"
                @load="value = item.achieveQuantity"
                @change="changeAchieveQuantityTxt(item, $event, index)"
                :ref="'achieveQuantity' + index"
              >
              </v-text-field>
            </template>
            <!-- 単価係数 -->
            <template v-slot:[`item.convertedValue`]="{ item, value, index }">
              <v-text-field
                :value="value"
                outlined
                dense
                :rules="[
                  !item.billingItems || rules.required,
                  !item.billingItems || rules.isNumber,
                  !item.billingItems || rules.isZero,
                  !item.billingItems || rules.checkConvertedValue,
                  (input) => {
                    let result =
                      !item.billingItems ||
                      checkMaxAmount(item.achieveQuantity, item.unitPrice, input);
                    return result;
                  },
                ]"
                class="input-number"
                maxLength="4"
                @load="value = item.convertedValue"
                @change="changeConvertedValueTxt(item, $event, index)"
                :ref="'convertedValue' + index"
              >
              </v-text-field>
            </template>
            <!-- 売上営業所 -->
            <template v-slot:[`item.officeSales`]="{ item, index }">
              <div id="table-officeSales">
                <v-autocomplete
                  v-model="item.officeSales"
                  :items="filteringOfficeSalesList(item)"
                  item-text="value"
                  :rules="[!item.billingItems || rules.required]"
                  persistent-hint
                  dense
                  class="cursorProcd"
                  :ref="'cursorProcd' + index"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>
            <!-- 倉庫コード/倉庫名 -->
            <template v-slot:[`item.warehouseCd`]="{ item, index }">
              <div id="table-warehouseCd">
                <v-autocomplete
                  v-model="item.warehouseCd"
                  :items="filteringWarehouseList(item)"
                  item-text="value"
                  persistent-hint
                  dense
                  class="cursorProcd"
                  :ref="'cursorProcd' + index"
                  @change="item.isUpdated = true"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>
            <!-- 税区分 -->
            <template v-slot:[`item.taxClassification`]="{ item, index }">
              <div id="table-taxClassification">
                <v-autocomplete
                  v-model="item.taxClassification"
                  :items="taxClassificationList"
                  :rules="[!item.billingItems || rules.required]"
                  persistent-hint
                  dense
                  class="cursorProcd"
                  :ref="'cursorProcd' + index"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>
            <!-- 計算区分 -->
            <template v-slot:[`item.calculationClassification`]="{ item, index }">
              <div id="table-calculationClassification">
                <v-autocomplete
                  v-model="item.calculationClassification"
                  :items="calculationClassificationList"
                  :rules="[!item.billingItems || rules.required]"
                  persistent-hint
                  dense
                  class="cursorProcd"
                  :ref="'cursorProcd' + index"
                  @change="changeCalculationClassification(item)"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>
            <!-- 振替率% -->
            <template v-slot:[`item.transferRate`]="{ item, value }">
              <v-text-field
                :value="value"
                outlined
                dense
                :rules="[
                  !item.billingItems || baseCd === item.officeSales || rules.required,
                  !item.billingItems || baseCd === item.officeSales || rules.isNumber,
                  !item.billingItems || baseCd === item.officeSales || rules.checkTransferRate,
                  !item.billingItems || baseCd === item.officeSales || rules.isHalfWidthNumber,
                  !item.billingItems || baseCd === item.officeSales || rules.isValidtransferRate,
                ]"
                class="input-number"
                maxLength="3"
                @load="value = item.transferRate"
                :disabled="isEmpty(item.billingItems) ? true : baseCd === item.officeSales"
                @change="changeTransferRate(item, $event)"
              >
              </v-text-field>
            </template>
            <template v-slot:[`item.editExpireDate`]="{ item, index }">
              <v-btn
                small
                @click="editExpireDate(item, index)"
                text
                :color="item.hasExpireDateError ? '#F44336' : ''"
                :ref="'editExpireDate' + index"
                :id="'editExpireDate' + index"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.deleteData`]="{ item }">
              <v-btn small @click="deleteItem(item)" text>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-form>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :bilCotractUnitPriceRegistrationFlg="infoDialog.bilCotractUnitPriceRegistrationFlg"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 有効期限設定モーダル -->
      <v-dialog v-model="isOpenEditExpireDateDialog" :max-width="1300" persistent>
        <editExpireDateDialog
          :isOpenDetailDialog.sync="isOpenEditExpireDateDialog"
          :selectedExpireDateList="selectedExpireDateList"
          :viewDataList="inputList"
          :selectedIndex="selectedIndex"
          :_deleteList="deleteList"
          :fiveInDiv="fiveInDiv"
          v-on:child-event="parentMethod"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { i18n } from "../../lang/lang.js";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import editExpireDateDialog from "./BillingEditExpireDate";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
export default {
  name: appConfig.SCREEN_ID.P_BIL_004,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
    editExpireDateDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    invalidPriceMsg: i18n.tc("check.chk_limitPrice"),
    // ローディング画面表示フラグ
    loadingCounter: 0,
    updateDialogMessage: false,
    u_10: false,
    alertMessage: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    // 取引先
    suppliersSelected: "",
    baseCd: "",
    loginUserOffice: [],
    // 倉庫
    warehouseList: [],
    billingBussinessNoSelected: "",
    // 請求No(業務No)
    billingBussinessNo: "",
    // 業務名
    bussinessName: "",
    // 選択行
    selectedIndex: 0,
    // メニュー
    openMenu: null,
    isOpenEditExpireDateDialog: false,
    fiveInDiv: "",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      bilCotractUnitPriceRegistrationFlg: false,
      outsideClickNotCloseFlg: false,
    },
    // ヘッダ
    headerItems: [
      // 項目/項目内容
      {
        text: i18n.tc("label.lbl_billingItems"),
        value: "billingItems",
        width: "7%",
        align: "center",
        class: "asta",
      },
      // 科目/科目内容
      {
        text: i18n.tc("label.lbl_billingSubject"),
        value: "billingSubject",
        width: "4.5%",
        align: "center",
        class: "asta",
      },
      // 単価コード/単価名
      {
        text: i18n.tc("label.lbl_unitPrice") + "\r\n" + i18n.tc("label.lbl_classification"),
        value: "unitPriceClassificPrice",
        width: "4.5%",
        align: "left",
        class: "asta",
      },
      // 単価内訳
      {
        text: i18n.tc("label.lbl_unitPriceClassContents"),
        value: "unitPriceClassContents",
        width: "11.5%",
        align: "left",
        class: "asta",
      },
      // 単価Gr/単価Gr名
      {
        text: i18n.tc("label.lbl_unitPriceGr"),
        value: "unitPriceType",
        width: "4.5%",
        align: "left",
      },
      // 有効期限
      {
        text: i18n.tc("label.lbl_dateOfExpiry"),
        value: "dateOfExpiry",
        width: "4%",
        align: "left",
      },
      // 単価
      {
        text: i18n.tc("label.lbl_unitPrice"),
        value: "unitPrice",
        width: "8%",
        align: "left",
        class: "asta",
      },
      // 単価単位
      {
        text: i18n.tc("label.lbl_unitPriceUnit"),
        value: "unitPriceUnit",
        width: "4.5%",
        align: "left",
        class: "asta",
      },
      // 固定数量
      {
        text: i18n.tc("label.lbl_fixedQuantity"),
        value: "achieveQuantity",
        width: "5.5%",
        align: "left",
      },
      // 単価係数
      {
        text: i18n.tc("label.lbl_convertedValue"),
        value: "convertedValue",
        width: "5%",
        align: "left",
        class: "asta",
      },
      // 売上営業所
      {
        text: i18n.tc("label.lbl_sales") + "\r\n" + i18n.tc("label.lbl_salesOffice"),
        value: "officeSales",
        width: "5%",
        align: "left",
        class: "asta",
      },
      // 倉庫コード
      {
        text: i18n.tc("label.lbl_warehouseCd"),
        value: "warehouseCd",
        width: "6.5%",
        align: "left",
      },
      // 税区分
      {
        text: i18n.tc("label.lbl_taxClassification"),
        value: "taxClassification",
        width: "6%",
        align: "left",
        class: "asta",
      },
      // 計算区分
      {
        text: i18n.tc("label.lbl_calculationClassification"),
        value: "calculationClassification",
        width: "7%",
        align: "left",
        class: "asta",
      },
      // 振替率%
      {
        text: i18n.tc("label.lbl_transferRate"),
        value: "transferRate",
        width: "5%",
        align: "left",
        class: "asta",
      },
      // 有効期限設定
      {
        text: i18n.tc("label.lbl_editExpireDate"),
        value: "editExpireDate",
        width: "2%",
        align: "center",
      },
      // 削除
      { text: i18n.tc("btn.btn_delete"), value: "deleteData", width: "2.5%", align: "center" },
    ],
    inputList: [],
    masterList: [],
    deleteList: [],
    tmpList: [],
    selectedExpireDateList: [],
    supplierList: [],
    billingBussinessNoList: [],
    officeSalesList: [],
    unitPriceUnitList: [],
    taxClassificationList: [],
    calculationClassificationList: [],
    unitPriceClassificPriceList: [],
    unitPriceClassificPriceListBase: [],
    unitPriceClassificPriceForSystemList: [],
    unitPriceTypeList: [],
    billingItemList: [],
    billingSubjectList: [{ text: "", name: "", value: "" }],
    versionSid: "",
    backMessage: "",
    inValidExpireDateList: [],
    isInit: false,
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    rules: {
      required: function (value) {
        if (value !== undefined && value !== null && value !== "") {
          value = String(value);
          value = value.replace(/\s+/g, "");
          if (value === "") {
            return i18n.tc("check.chk_input");
          }
        } else {
          return i18n.tc("check.chk_input");
        }
        return true;
      },
      required_: function (value, item) {
        if (!value && (!!item.billingItems || !!item.billingSubject)) {
          return i18n.tc("check.chk_input");
        }
        return true;
      },
      price: function (value) {
        let isValid;
        if (value !== undefined && value !== null && value !== "") {
          value = String(value);
          if (
            (value.includes("¥") && 0 < value.indexOf("¥")) ||
            value.indexOf("¥") != value.lastIndexOf("¥")
          ) {
            return i18n.tc("check.chk_inputNumber_7");
          }
          value = value.replaceAll(",", "").replaceAll("¥", "");
        }
        const regex = /^([1-9])(\d{0,6})$/;
        if (regex.test(Number(value))) {
          isValid = true;
        } else {
          isValid = false;
        }
        if (!isValid) {
          return i18n.tc("check.chk_inputNumber_7");
        }
        return true;
      },
      isNumber: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "").replaceAll("¥", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      isZero: function (value) {
        let strVal = String(value);
        let enInclude = strVal.includes("¥", 0);
        if (enInclude) {
          strVal = strVal.slice(1);
        }
        if (Number(strVal) == 0) {
          return i18n.tc("check.chk_inputThanZero");
        }
        return true;
      },
      calculationClassification: function (value, item) {
        if (!value && item.calculationClassification == "03") {
          return i18n.tc("check.chk_input");
        }
        return true;
      },
      checkConvertedValue: function (value) {
        let isValid;
        if (value !== undefined && value !== null && value !== "") {
          value = String(value);
          value.replaceAll(",", "").replaceAll("¥", "");
        }
        const regex = /^([0-1])(\.\d{1,2})$|^[0-1]$/;
        if (regex.test(Number(value))) {
          isValid = true;
        } else {
          isValid = false;
        }
        if (!isValid) {
          return i18n.tc("check.chk_inputNumber_1-2");
        }
        return true;
      },
      checkQuantity: function (value) {
        let isValid;
        value = String(value).replaceAll(",", "");
        const regex = /^([1-9])(\d{0,4})$/;
        if (regex.test(Number(value))) {
          isValid = true;
        } else {
          isValid = false;
        }
        if (!isValid) {
          return i18n.tc("check.chk_inputNumber_5");
        }
        return true;
      },
      maxLength22: function (value) {
        if (22 < value.length) {
          return i18n.tc("check.chk_limitLength_22");
        }
        return true;
      },
      isValidUnitPriceClassContents: function (isValid) {
        if (!isValid) {
          return i18n.tc("check.chk_inputFullWidth");
        }
        return true;
      },
      isValidPrice(isValid) {
        if (!isValid) {
          return i18n.tc("check.chk_limitPrice");
        }
        return true;
      },
      isValidtransferRate(value) {
        let num = Number(value);
        if (num < 1 || num > 100) {
          return i18n.tc("check.chk_inputNumberRange_1-100");
        }
        return true;
      },
      isHalfWidthNumber(value) {
        let isValid = String(value).match(/[０-９]/g) === null;
        if (!isValid) {
          return i18n.tc("check.chk_inputHalfWidth");
        }
        return true;
      },
      checkTransferRate: function (value) {
        let isValid;
        const regex = /^(\d{0,3})$/;
        if (regex.test(Number(value))) {
          isValid = true;
        } else {
          isValid = false;
        }
        if (!isValid) {
          return i18n.tc("check.chk_inputInteger");
        }
        return true;
      },
    },
  }),
  methods: {
    // 取引先API接続
    getSupplierList(isClient) {
      // 取引先
      let clientList;
      if (isClient === appConfig.ISGETCLIENT.CLIENT) {
        clientList = getParameter.getRelatedBiz(isClient, dateTimeHelper.convertJST());
      } else {
        clientList = getParameter.getRelatedBiz(
          isClient,
          dateTimeHelper.convertJST(),
          false,
          sessionStorage.getItem("comp_sid")
        );
      }

      return Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          if (isClient === appConfig.ISGETCLIENT.CLIENT) {
            this.supplierList = result[0];
          } else {
            this.loginUserOffice = result[0];
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        });
    },
    // 単価CDマスタ取得
    getUnitPriceCdList() {
      // 取引先
      const unitPriceCdList = getParameter.getUnitPriceCdList("0");
      return Promise.all([unitPriceCdList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.unitPriceClassificPriceListBase = result[0];
          this.unitPriceClassificPriceList = result[0];
          this.unitPriceClassificPriceForSystemList = this.unitPriceClassificPriceList.filter(
            (value) => {
              return (
                value.value.includes("01") ||
                value.value.includes("02") ||
                value.value.includes("03")
              );
            }
          );
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        });
    },
    // 単価Grマスタ取得
    getUnitPriceGrList() {
      // 単価Gr
      const unitPriceGrList = getParameter.getUnitPriceGrList("0");
      return Promise.all([unitPriceGrList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.unitPriceTypeList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        });
    },
    // 業務No取得
    getBizspecList() {
      // 取引先
      const businessNoList = getParameter.getBizSpecList("0", this.suppliersSelected);
      return Promise.all([businessNoList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.billingBussinessNoList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        });
    },
    // G請求項目マスタ取得
    getInvoiceItemList(billingItem) {
      let target = this.billingSubjectList.filter((value) => {
        return value.key != void 0 && value.key != void 0 && value.key == billingItem;
      });
      if (0 < target.length) {
        return this.billingSubjectList;
      }
      // G請求項目マスタ
      const invoiceItemList = getParameter.getInvoiceItemList(
        "0",
        this.suppliersSelected,
        this.billingBussinessNo,
        billingItem
      );
      return Promise.all([invoiceItemList])
        .then((result) => {
          // 画面の初期値を設定します。
          if (billingItem !== void 0) {
            this.billingSubjectList = [...this.billingSubjectList, ...result[0]];
          } else {
            this.billingItemList = result[0];
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        });
    },
    // 単位マスタ取得
    getUnitCdList() {
      // 取引先
      const unitList = getParameter.getUnitCdList("0");
      return Promise.all([unitList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.unitPriceUnitList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        });
    },

    // 倉庫マスタ取得
    getWarehouse() {
      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isMst = "0";
      config.params.searchCategory = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_WAREHOUSE, config)
          .then((response) => {
            // console.debug("getWarehouse() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "", value: "" }];
              jsonData.resIdv.warehouses.forEach((row) => {
                list.push({
                  text: row.warehouseLanguage[0].warehouseName,
                  value: row.warehouseLanguage[0].warehouseSid,
                  name: row.warehouseLanguage[0].warehouseName,
                });
              });
              this.warehouseList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          });
      });
    },
    // 登録ボタン
    registration() {
      if (this.$refs.editedList.validate()) {
        let target = this.inputList.filter((value) => {
          return !!value.billingItems;
        });
        let registrationTarget = JSON.parse(JSON.stringify(target));
        let childItemList = [];
        const len = registrationTarget.length;
        for (let i = 0; i < len; i++) {
          let registrationItem = registrationTarget[i];
          registrationItem.deleteFlg = "0";
          registrationItem.unitPrice = this.convertAmountToNumber(registrationItem.parentUnitPrice);
          registrationItem.expireDateTo = registrationItem.expireDateList[0].expireDateTo;
          registrationItem.expireDateFrom = registrationItem.expireDateList[0].expireDateFrom;
          let billingSubjectList = this.billingSubjectList;
          let masterData = [];
          if (registrationItem.isNewCreation) {
            masterData = billingSubjectList.filter((obj) => {
              let invoiceItemSid = registrationItem.billingSubject;
              return obj.value == invoiceItemSid;
            });
          }
          registrationItem.appOfficeSid =
            0 < masterData.length ? masterData[0].officeSid : registrationItem.appOfficeSid;
          for (let j = 1; j < registrationItem.expireDateList.length; j++) {
            let childItem = registrationItem.expireDateList[j];
            if (!childItem.expireDateTo || !childItem.expireDateFrom || !childItem.unitPrice) {
              continue;
            }
            let registraionChildItem = Object.assign({}, registrationItem);
            registraionChildItem.expireDateTo = childItem.expireDateTo;
            registraionChildItem.expireDateFrom = childItem.expireDateFrom;
            registraionChildItem.unitPrice = this.convertAmountToNumber(childItem.unitPrice);
            registraionChildItem.unitPriceSid = childItem.unitPriceSid;
            registraionChildItem.updateDatetime = childItem.updateDatetime;
            childItemList.push(registraionChildItem);
          }
        }

        for (let i = 0; i < this.deleteList.length; i++) {
          for (let j = 1; j < this.deleteList[i].expireDateList.length; j++) {
            let deleteItemBase = this.deleteList[i];
            let deleteItem = Object.assign({}, deleteItemBase);
            deleteItem.unitPriceSid = deleteItemBase.expireDateList[j].unitPriceSid;
            deleteItem.updateDatetime = deleteItemBase.expireDateList[j].updateDatetime;
            deleteItem.expireDateFrom = deleteItemBase.expireDateList[j].expireDateFrom;
            deleteItem.expireDateTo = deleteItemBase.expireDateList[j].expireDateTo;
            deleteItem.unitPrice = this.convertAmountToNumber(
              deleteItemBase.expireDateList[i].unitPrice
            );
            deleteItem.deleteFlg = "1";
            registrationTarget.push(deleteItem);
          }
          registrationTarget.push(this.deleteList[i]);
        }
        registrationTarget = [...registrationTarget, ...childItemList];
        if (0 < registrationTarget.length) {
          this.postApi(registrationTarget);
        } else {
          this.endEvent("btn.btn_complete");
        }
      } else {
        this.scrollToErrorRow();
        return;
      }
    },
    // イベント終了ダイアログ表示
    endEvent(button) {
      this.deleteList = [];
      this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [i18n.tc(button)]);
      this.infoDialog.title = "結果";
      this.infoDialog.isOpen = true;
      this.infoDialog.screenFlag = true;
      this.infoDialog.bilCotractUnitPriceRegistrationFlg = true;
    },
    // 初期化
    init() {
      this.loadingCounter = 1;
      this.isInit = true;
      this.suppliersSelected = "";
      this.billingBussinessNo = "";
      this.bussinessName = "";
      this.inputList = [];
      let param = this.$route.params.selectData;
      if (param != null) {
        this.suppliersSelected = param.suppliersSelected;
        this.billingBussinessNoSelected = param.bussinessNo;
        this.billingBussinessNo = param.bussinessNo;
        this.bussinessName = param.bussinessName;
        const sup = this.getSupplierList(appConfig.ISGETCLIENT.CLIENT);
        const logSup = this.getSupplierList(appConfig.ISGETCLIENT.FROMTO);
        const priceCd = this.getUnitPriceCdList();
        const unitPriceGr = this.getUnitPriceGrList();
        const unitCd = this.getUnitCdList();
        const _0601 = this.getMstCode("0601");
        const _0602 = this.getMstCode("0602");
        const bizSpec = this.getBizspecList();
        const business = this.getBusinessMst();
        // ドロップダウンに表示する情報を取得した後に明細部の情報を取得する
        Promise.all([
          sup,
          logSup,
          priceCd,
          unitPriceGr,
          unitCd,
          _0601,
          _0602,
          bizSpec,
          business,
        ]).then(() => {
          this.setBaseCd();
          this.getRegistrationData();
        });
      } else {
        this.loadingCounter = 0;
      }
    },
    /**
     * 明細部に表示する情報を取得する
     */
    getRegistrationData() {
      const invoiceItemList = getParameter.getInvoiceItemList(
        "0",
        this.suppliersSelected,
        this.billingBussinessNo
      );
      Promise.all([invoiceItemList])
        .then((result) => {
          this.billingItemList = result[0];
          this.getBillingContractDeteil();
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        });
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    // 取引先コードリストボックス取得処理
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    // 請求No(業務No)リストボックス取得処理
    setBillingBussinessNo() {
      for (let i = 0; i < this.billingBussinessNoList.length; i++) {
        if (this.billingBussinessNoList[i].value == this.billingBussinessNoSelected) {
          return this.billingBussinessNoList[i].name;
        }
      }
    },

    /**
     * 倉庫コードリストボックス取得処理
     */
    checkWarehouseCd(value) {
      for (var i = 0; i < this.warehouseList.length; i++) {
        if (this.warehouseList[i].value == value.warehouseCd) {
          return this.warehouseList[i].name;
        }
      }
    },
    /**
     * 売上営業所リストボックス取得処理
     */
    checkOfficeSales(value) {
      for (var i = 0; i < this.officeSalesList.length; i++) {
        if (this.officeSalesList[i].value == value.officeSales) {
          return this.officeSalesList[i].name;
        }
      }
    },
    /**
     * 項目リストボックス取得処理
     */
    checkBillingItems(value) {
      for (var i = 0; i < this.billingItemList.length; i++) {
        if (this.billingItemList[i].value == value.billingItems) {
          return this.billingItemList[i];
        }
      }
      return { value: "", name: "", text: "" };
    },
    // 請求項目名称取得処理
    getBillingItemsText(value) {
      if (this.isEmpty(value.billingItems)) {
        return "";
      }
      for (var i = 0; i < this.billingItemList.length; i++) {
        if (this.billingItemList[i].value == value.billingItems) {
          return this.billingItemList[i].value + "\r\n" + this.billingItemList[i].name;
        }
      }
    },
    /**
     * 単価単位リストボックス取得処理
     */
    checkUnitPriceUnitList(value) {
      for (var i = 0; i < this.unitPriceUnitList.length; i++) {
        if (this.unitPriceUnitList[i].value == value.unitPriceUnit) {
          return this.unitPriceUnitList[i].name;
        }
      }
    },
    /**
     * 税区分リストボックス取得処理
     */
    checkTaxClassifications(value) {
      for (var i = 0; i < this.taxClassificationList.length; i++) {
        if (this.taxClassificationList[i].value == value.taxClassification) {
          return this.taxClassificationList[i].name;
        }
      }
    },
    /**
     * 計算区分リストボックス取得処理
     */
    checkCalculationClassifications(value) {
      for (var i = 0; i < this.calculationClassificationList.length; i++) {
        if (this.calculationClassificationList[i].value == value.calculationClassification) {
          return this.calculationClassificationList[i].name;
        }
      }
    },
    /**
     * 単価分類リストボックス取得処理
     */
    checkUnitPriceClassificPriceList(value) {
      for (var i = 0; i < this.unitPriceClassificPriceList.length; i++) {
        if (this.unitPriceClassificPriceList[i].value == value.unitPriceClassificPrice) {
          return this.unitPriceClassificPriceList[i].name;
        }
      }
    },
    /**
     * 単価種類リストボックス取得処理
     */
    checkUnitPriceTypeList(value) {
      for (var i = 0; i < this.unitPriceTypeList.length; i++) {
        if (this.unitPriceTypeList[i].value == value.unitPriceType) {
          return this.unitPriceTypeList[i].name;
        }
      }
    },
    /**
     * 科目リストボックス取得処理
     */
    checkBillingSubject(value) {
      for (var i = 0; i < this.billingSubjectList.length; i++) {
        if (this.billingSubjectList[i].value == value.billingSubject) {
          return this.billingSubjectList[i];
        }
      }
      return { value: "", name: "", text: "" };
    },
    // 科目項目名称取得処理
    getBillingSubjectText(value) {
      if (this.isEmpty(value.billingSubject)) {
        return "";
      }
      if (!value.isNewCreation && !value.invoiceItemSid) {
        return this.checkBillingSubject(value).name;
      }
      return value.billingSubject;
    },
    // 項目が空かチェック
    isEmpty(value) {
      if (value === undefined || value === null || value === "") {
        return true;
      }
      return false;
    },
    // 行追加
    addItem() {
      for (let i = 0; i < 10; i++) {
        let item = {
          index: 0,
          billingItems: "",
          billingSubject: "",
          billingSubjectCd: "",
          unitPriceClassificPrice: "",
          unitPriceClassContents: "",
          unitPriceType: "",
          dateOfExpiry: "",
          expireDateFrom: "",
          expireDateTo: "",
          expireDateList: [
            {
              expireDateFrom: null,
              expireDateTo: null,
              unitPrice: null,
            },
          ],
          unitPrice: "",
          unitPriceUnit: "",
          achieveQuantity: "",
          convertedValue: "1.00",
          officeSales: "",
          warehouseCd: "",
          taxClassification: "",
          calculationClassification: "",
          isNewCreation: false,
          periodDiv: "",
          appWarehouseSid: "",
          appOfficeSid: "",
          deleteFlg: "0",
          hasExpireDateError: false,
          displayUnitPriceListIndex: 0,
          transferRate: "",
          duplicateBillingItemMsg: "",
        };
        let startDate = "2022/04/01";
        let endDate = "2099/12/31";
        item.dateOfExpiry = startDate.substr(2) + "\r\n" + endDate.substr(2);
        item.expireDateFrom = startDate;
        item.expireDateTo = endDate;
        for (let i = 0; i < item.expireDateList.length; i++) {
          if (i == 0) {
            item.expireDateList[i].expireDateFrom = startDate;
            item.expireDateList[i].expireDateTo = endDate;
          } else {
            item.expireDateList[i].expireDateFrom = null;
            item.expireDateList[i].expireDateTo = null;
          }
        }
        let index = this.createIndex();
        item.index = index;
        item.isNewCreation = true;
        this.inputList.push(Object.assign({}, item));
        this.addWatcheForDuplicateBillingItem(this.inputList[this.inputList.length - 1]);
      }
      this.scrollToEnd();
    },
    filteringSubjectList(item) {
      return this.billingSubjectList.filter((value) => {
        return value.key == item;
      });
    },
    filteringUnitPriceClassificPriceList(item) {
      if (item.calculationClassification == "03") {
        if (
          item.unitPriceClassificPrice != "01" &&
          item.unitPriceClassificPrice != "02" &&
          item.unitPriceClassificPrice != "03"
        ) {
          item.unitPriceClassificPrice = "";
        }
        return this.unitPriceClassificPriceForSystemList;
      } else {
        return this.unitPriceClassificPriceListBase;
      }
    },
    filteringWarehouseList(item) {
      if (this.isEmpty(item.billingSubject)) {
        return [];
      }
      let result = this.billingItemList.filter((obj) => {
        return obj.warehouseSid == item.appWarehouseSid;
      });
      if (0 === result.length) {
        item.appWarehouseSid = "";
        result = [{ value: "", text: "" }];
      } else {
        item.appWarehouseSid = result[0].warehouseSid;
        result = [{ value: result[0].warehouseCd, text: result[0].warehouseCd }];
      }
      return result;
    },
    filteringOfficeSalesList(item) {
      if (this.isEmpty(item.billingSubject)) {
        return [];
      }
      let result = this.officeSalesList.filter((obj) => {
        return obj.value == item.officeSales;
      });
      return 0 < result.length ? result : [{ value: "", text: "" }];
    },
    changed() {},
    /**
     * 行削除処理
     */
    deleteItem(item) {
      item.deleteFlg = "1";
      this.inputList = this.inputList.filter((value) => {
        return value.index != item.index;
      });
      let targets = this.inputList.filter((element) => {
        return (
          element.billingItems === item.billingItems &&
          element.billingSubjectCd === item.billingSubjectCd &&
          element.unitPriceClassificPrice === item.unitPriceClassificPrice &&
          element.unitPriceType === item.unitPriceType &&
          element.unitPriceClassContents === item.unitPriceClassContents
        );
      });
      if (1 === targets.length) {
        targets[0].duplicateBillingItemMsg = "";
      }
      if (!item.invoiceItemSid) {
        return;
      }
      if (item.hasExpireDateError) {
        item.hasExpireDateError = false;
        this.inValidExpireDateList.splice(this.inValidExpireDateList.indexOf(item), 1);
      }
      for (let i = 1; i < item.expireDateList.length; i++) {
        let deleteItem = Object.assign({}, item);
        deleteItem.unitPriceSid = item.expireDateList[i].unitPriceSid;
        deleteItem.updateDatetime = item.expireDateList[i].updateDatetime;
        deleteItem.unitPrice = this.convertAmountToNumber(item.expireDateList[i].unitPrice);
        deleteItem.deleteFlg = "1";
        deleteItem.expireDateList = [];
        this.deleteList.push(deleteItem);
      }
      item.expireDateList = [];
      item.unitPrice = this.convertAmountToNumber(item.unitPrice);
      this.deleteList.push(item);
    },
    /**
     * 有効期限設定ダイアログを表示
     */
    editExpireDate(item, index) {
      item.expireDateList[0].unitPrice = item.parentUnitPrice;
      this.selectedExpireDateList = item.expireDateList;
      this.selectedIndex = index;
      this.isOpenEditExpireDateDialog = true;
    },
    /**
     * インデックスを作成する
     */
    createIndex() {
      if (this.inputList.length == 0) {
        return 0;
      }
      return this.inputList[this.inputList.length - 1].index + 1;
    },
    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action;
      switch (button) {
        case "btn_complete":
          if (this.inputList.length == 0 && this.deleteList.length == 0) {
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-RCV-001_006_E");
            return;
          }
          if (this.isDuplicateBillingItemError()) {
            this.scrollToErrorRow();
            return;
          }
          if (this.$refs.editedList.validate() && this.inValidExpireDateList.length != 0) {
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-BIL-004_001_E");
            this.scrollToErrorRow();
            return;
          }
          if (!this.isDataUpdated()) {
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-BIL-005_001_E");
            return;
          }
          isValidationRequired = true;
          message = "P-RCV-001_003_C";
          action = this.registration;
          break;
        case "btn_back":
          isValidationRequired = false;
          message = "P-COM-001_004_W";
          action = this.backTo;
          break;
        default:
          isValidationRequired = true;
          message = "";
      }
      if (isValidationRequired) {
        let isValid = this.$refs.editedList.validate();
        if (!isValid) {
          this.scrollToErrorRow();
          return;
        }
      }
      this.ConfirmDialog.message = messsageUtil.getMessage(message);
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = action;
    },

    /**
     * 前画面へ戻る
     */
    backTo() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_BIL_001,
        params: {
          selectData: this.suppliersSelected,
        },
      });
    },

    /**
     * カンマ付きの数字に変換する
     */
    parseAmountFormat(value, index) {
      if (!value || value.unitPrice == null) {
        return value;
      }
      let inputPrice = String(value.unitPrice);
      let isDecimal = 1 < inputPrice.split(".").length;
      let decimal;
      if (isDecimal) {
        decimal = inputPrice.split(".")[1];
      }
      inputPrice = inputPrice.split(".")[0];
      inputPrice = commonUtil.zen2han(inputPrice);
      let enInclude = inputPrice.includes("¥", 0);
      if (enInclude) {
        inputPrice = inputPrice.slice(1);
      }
      if (isNaN(Number(inputPrice.replaceAll(",", "")))) {
        return;
      }
      inputPrice = String(Number(inputPrice.replaceAll(",", "")));
      let delimiter = ",";
      let len = inputPrice.length;
      let delimiterPoint = 3;
      if (len >= delimiterPoint) {
        let numOfDeliimiter =
          len % delimiterPoint == 0 ? len / delimiterPoint - 1 : len / delimiterPoint;

        for (let i = 1; i <= numOfDeliimiter; i++) {
          let index = len - delimiterPoint * i;
          let previousTxt = inputPrice.slice(0, index);
          let behindTxt = inputPrice.slice(index);
          inputPrice = previousTxt + delimiter + behindTxt;
        }
      }
      let price = this.strIns(inputPrice, 0, "¥");
      if (isDecimal && decimal != 0) {
        price = this.strIns(price, price.length, "." + decimal);
      }
      this.inputList[index].unitPrice = price;
      value.unitPrice = price;
      this.inputList[index].expireDateList[value.displayUnitPriceListIndex].unitPrice = price;
    },
    /**
     * カンマ付きの数字に変換する
     */
    parseQuantityFormat(value, index) {
      if (!value || value.achieveQuantity == null) {
        return value;
      }
      let achieveQuantity = String(value.achieveQuantity);
      let isDecimal = 1 < achieveQuantity.split(".").length;
      let decimal;
      if (isDecimal) {
        decimal = achieveQuantity.split(".")[1];
      }
      achieveQuantity = achieveQuantity.split(".")[0];
      achieveQuantity = commonUtil.zen2han(achieveQuantity);
      if (isNaN(Number(achieveQuantity.replaceAll(",", "")))) {
        return;
      }
      achieveQuantity = String(Number(achieveQuantity.replaceAll(",", "")));
      let delimiter = ",";
      let len = achieveQuantity.length;
      let delimiterPoint = 3;
      if (len >= delimiterPoint) {
        let numOfDeliimiter =
          len % delimiterPoint == 0 ? len / delimiterPoint - 1 : len / delimiterPoint;

        for (let i = 1; i <= numOfDeliimiter; i++) {
          let index = len - delimiterPoint * i;
          let previousTxt = achieveQuantity.slice(0, index);
          let behindTxt = achieveQuantity.slice(index);
          achieveQuantity = previousTxt + delimiter + behindTxt;
        }
      }
      if (isDecimal && decimal != 0) {
        achieveQuantity = this.strIns(achieveQuantity, achieveQuantity.length, "." + decimal);
      }
      this.inputList[index].achieveQuantity = achieveQuantity;
      value.achieveQuantity = achieveQuantity;
    },
    /**
     * カンマ付きの数字に変換する
     */
    changeAmountFormat(value) {
      if (!value) {
        return value;
      }
      let inputPrice = String(value);
      inputPrice = commonUtil.zen2han(inputPrice);
      let enInclude = inputPrice.includes("¥", 0);
      if (enInclude) {
        inputPrice = inputPrice.slice(1);
      }
      let isDecimal = 1 < inputPrice.split(".").length;
      let decimal;
      if (isDecimal) {
        decimal = inputPrice.split(".")[1];
      }
      inputPrice = inputPrice.split(".")[0].replaceAll(",", "");
      let delimiter = ",";
      let len = inputPrice.length;
      let delimiterPoint = 3;
      if (len >= delimiterPoint) {
        let numOfDeliimiter =
          len % delimiterPoint == 0 ? len / delimiterPoint - 1 : len / delimiterPoint;

        for (let i = 1; i <= numOfDeliimiter; i++) {
          let index = len - delimiterPoint * i;
          let previousTxt = inputPrice.slice(0, index);
          let behindTxt = inputPrice.slice(index);
          inputPrice = previousTxt + delimiter + behindTxt;
        }
      }
      inputPrice = this.strIns(inputPrice, 0, "¥");
      if (isDecimal && decimal != 0) {
        inputPrice = this.strIns(inputPrice, inputPrice.length, "." + decimal);
      }
      return inputPrice;
    },
    /**
     * カンマ付きの数字に変換する
     */
    changeQuantityFormat(value) {
      if (!value) {
        return value;
      }
      let achieveQuantity = String(value);
      achieveQuantity = commonUtil.zen2han(achieveQuantity);
      let isDecimal = 1 < achieveQuantity.split(".").length;
      let decimal;
      if (isDecimal) {
        decimal = achieveQuantity.split(".")[1];
      }
      achieveQuantity = achieveQuantity.split(".")[0].replaceAll(",", "");
      let delimiter = ",";
      let len = achieveQuantity.length;
      let delimiterPoint = 3;
      if (len >= delimiterPoint) {
        let numOfDeliimiter =
          len % delimiterPoint == 0 ? len / delimiterPoint - 1 : len / delimiterPoint;

        for (let i = 1; i <= numOfDeliimiter; i++) {
          let index = len - delimiterPoint * i;
          let previousTxt = achieveQuantity.slice(0, index);
          let behindTxt = achieveQuantity.slice(index);
          achieveQuantity = previousTxt + delimiter + behindTxt;
        }
      }
      if (isDecimal && decimal != 0) {
        achieveQuantity = this.strIns(achieveQuantity, achieveQuantity.length, "." + decimal);
      }
      return achieveQuantity;
    },
    /**
     * 金額を整数に変換する
     */
    convertAmountToNumber(value) {
      if (value == void 0 || value == null) {
        return value;
      }
      value = String(value);
      let enInclude = value.includes("¥", 0);
      if (enInclude) {
        value = value.slice(1);
      }
      return value.replaceAll(",", "");
    },
    /**
     * 固定数量の入力チェック
     */
    validateAchieveQuantity(item) {
      if (item.calculationClassification == "01") {
        if (!item.achieveQuantity) {
          return i18n.tc("check.chk_input");
        }
      }
    },
    /**
     * 指定の位置に文字列を挿入する
     */
    strIns(str, idx, val) {
      return str.slice(0, idx) + val + str.slice(idx);
    },
    /**
     * 行データが更新されているかを確認する
     */
    isDataUpdated() {
      if (0 < this.deleteList.length) {
        return true;
      }
      for (let i = 0; i < this.inputList.length; i++) {
        let item = this.inputList[i];
        if (this.isEmpty(item.billingItems)) {
          continue;
        }
        if (item.isNewCreation) {
          return true;
        }
        this.tmpList[i].isUpdated = item.isUpdated;
        let updateObj = JSON.stringify(this.objectSort(item));
        let tmpObj = JSON.stringify(this.objectSort(this.tmpList[i]));
        if (updateObj != tmpObj) {
          return true;
        }
        if (item.transferRate != item.registeredTransferRate) {
          return true;
        }
      }
      return false;
    },
    /**
     * 請求項目変更イベント
     */
    changeBillingItem(item) {
      item.billingSubject = "";
      item.officeSales = "";
      item.warehouseCd = "";
      item.transferRate = null;
      Promise.all([this.getInvoiceItemList(item.billingItems)]).then(() => {
        let subject = this.filteringSubjectList(item.billingItems);
        if (subject.length !== 0) {
          this.$set(
            this.inputList[this.inputList.indexOf(item)],
            "billingSubject",
            subject[0].value
          );
          item.warehouseCd = subject[0].warehouseCd;
          item.officeSales = subject[0].officeCd;
          item.appOfficeSid = subject[0].officeSid;
          item.appWarehouseSid = subject[0].warehouseSid;
          this.$set(
            this.inputList[this.inputList.indexOf(item)],
            "billingSubjectCd",
            subject[0].text
          );
          if (
            this.baseCd != item.officeSales &&
            item.isNewCreation &&
            this.isEmpty(item.transferRate)
          ) {
            item.transferRate = 90;
          }
        }
      });
    },
    /**
     * 計算区分変更イベント
     */
    changeCalculationClassification(item) {
      if (item.calculationClassification != "01") {
        item.achieveQuantity = null;
        if (item.hasExpireDateError) {
          item.hasExpireDateError = false;
          this.inValidExpireDateList.splice(this.inValidExpireDateList.indexOf(item), 1);
        }
      }
    },
    /**
     * 単価係数を半角に変換する
     */
    convertedValChange(item) {
      let convertedVal = Number(
        commonUtil.zen2han(String(item.convertedValue).replaceAll("．", "."))
      );
      item.convertedValue = !isNaN(convertedVal) ? convertedVal : item.convertedValue;
    },
    /**
     * 固定数量を半角に変換する
     */
    achieveQuantityChange(item) {
      item.achieveQuantity = commonUtil.zen2han(item.achieveQuantity.replaceAll("．", "."));
      let isDecimal = 1 < String(item.achieveQuantity).split(".").length;
      let decimal;
      let num = String(item.achieveQuantity).split(".")[0];
      if (isDecimal) {
        decimal = String(item.achieveQuantity).split(".")[1];
        if (decimal == 0) {
          item.achieveQuantity = Number(num);
        }
      }
    },
    /**
     * オブジェクトをソートする
     */
    objectSort(obj) {
      if (obj == void 0 || obj == null) {
        return obj;
      }
      let entries = Object.entries(obj);
      if (entries == void 0 || entries == null) {
        return entries;
      }
      const sorted = entries.sort();
      for (let i in sorted) {
        const val = sorted[i][1];
        if (typeof val === "object") {
          sorted[i][1] = this.objectSort(val);
        }
      }
      return sorted;
    },
    /**
     * チェックエラーがある行までスクロールを移動する
     */
    scrollToErrorRow() {
      let table = this.$refs.listData.$el.childNodes[0];
      let element = document.querySelectorAll("[id^='editExpireDate']");
      let editIcon;
      for (let i = 0; i < element.length; i++) {
        let style = element[i].style.color;
        if (style != "") {
          editIcon = element[i];
          break;
        }
      }
      let invalidPosition = document.getElementsByClassName("error--text")[0];
      if (editIcon == null && invalidPosition == null) {
        return;
      }
      // バリデーションのstyleが適用されるまで待機した後に処理実行
      setTimeout(function () {
        let position;
        let invalidPositionOffsetTop = invalidPosition != null ? invalidPosition.offsetTop : 0;
        let editIconOffsetTop = editIcon != null ? editIcon.offsetTop : 0;
        if (invalidPositionOffsetTop != 0 && editIconOffsetTop != 0) {
          position =
            invalidPositionOffsetTop <= editIconOffsetTop
              ? invalidPositionOffsetTop
              : editIconOffsetTop;
        } else {
          position = invalidPositionOffsetTop == 0 ? editIconOffsetTop : invalidPositionOffsetTop;
        }

        editIconOffsetTop;
        let headerHeight = table.childNodes[0].rows[0].clientHeight;
        let rowHeight = table.childNodes[0].rows[1].clientHeight;
        let ratio = Math.floor((headerHeight / rowHeight) * Math.pow(10, 1)) / Math.pow(10, 1);
        rowHeight = ratio == 0.8 ? rowHeight : headerHeight / 0.8;
        table.scrollTo(0, position - (table.offsetTop + rowHeight));
      }, 100);
    },
    /**
     * 最終行にスクロールを移動する
     */
    scrollToEnd() {
      let table = this.$refs.listData.$el.childNodes[0];
      const lastIndex = table.childNodes[0].rows.length - 1;
      // 行追加後にスクロールバーの高さが変更されるまで待機した後に処理実行
      setTimeout(function () {
        table.scrollTo(0, table.childNodes[0].rows[lastIndex].offsetTop);
      }, 100);
    },
    // 金額計算処理
    checkPrice(item, isAll) {
      if (!item.billingItems) {
        return;
      }

      if (this.checkMaxAmount(item.achieveQuantity, item.unitPrice, item.convertedValue) !== true) {
        return;
      }
      let targets = item.expireDateList;
      let len = isAll ? item.expireDateList.length : 1;
      let isExist;
      if (isAll) {
        isExist =
          this.inValidExpireDateList.filter((obj) => {
            return obj.index == item.index;
          }).length != 0;
      }
      for (let i = 0; i < len; i++) {
        var qty = commonUtil.zen2han(item.achieveQuantity).replaceAll(",", "");
        var price = commonUtil
          .zen2han(targets[i].unitPrice)
          .replaceAll(",", "")
          .replaceAll("¥", "");
        var factor = commonUtil.zen2han(item.convertedValue).replaceAll(",", "");
        if (!isNaN(Number(qty)) && !isNaN(Number(price)) && !isNaN(Number(factor))) {
          let amount;
          switch (this.fiveInDiv) {
            case "01":
              // 四捨五入
              amount = Math.round(Number(qty * price * factor));
              break;
            case "02":
              // 切り上げ
              amount = Math.ceil(Number(qty * price * factor));
              break;
            case "03":
              // 切り捨て
              amount = Math.floor(Number(qty * price * factor));
              break;
            default:
              amount = Math.round(Number(qty * price * factor));
          }
          let isValid = amount <= 999999999;
          if (!isValid) {
            if (i != item.displayUnitPriceListIndex) {
              if (
                this.rules.price(price) !== true ||
                this.rules.checkQuantity(qty) !== true ||
                this.rules.checkConvertedValue(factor) !== true
              ) {
                continue;
              }
              if (!isExist) {
                this.inValidExpireDateList.push(item);
              }
              item.hasExpireDateError = true;
              this.updateDialogMessage = true;
              this.backMessage = messsageUtil.getMessage("P-BIL-004_001_E");
              return;
            }
            return i18n.tc("check.chk_limitPrice");
          }
        } else {
          if (0 < i) {
            item.hasExpireDateError = true;
            return;
          }
          return i18n.tc("check.chk_limitPrice");
        }
      }
      if (isAll) {
        item.hasExpireDateError = false;
        if (isExist) {
          this.inValidExpireDateList.splice(this.inValidExpireDateList.indexOf(item), 1);
        }
      }
    },
    // 金額最大値チェック
    checkMaxAmount(achieveQuantity, unitPrice, convertedValue) {
      var qty = commonUtil.zen2han(achieveQuantity).replaceAll(",", "");
      var price = commonUtil.zen2han(unitPrice).replaceAll(",", "").replaceAll("¥", "");
      var factor = commonUtil.zen2han(convertedValue).replaceAll(",", "");
      const maxPrice = 999999999;
      if (
        this.rules.price(price) !== true ||
        this.rules.checkQuantity(qty) !== true ||
        this.rules.checkConvertedValue(factor) !== true
      ) {
        // 他の優先バリデーションチェックでエラーになっている場合は無視する
        return true;
      }
      if (!isNaN(Number(qty)) && !isNaN(Number(price)) && !isNaN(Number(factor))) {
        let amount;
        switch (this.fiveInDiv) {
          case "01":
            // 四捨五入
            amount = Math.round(Number(qty * price * factor));
            break;
          case "02":
            // 切り上げ
            amount = Math.ceil(Number(qty * price * factor));
            break;
          case "03":
            // 切り捨て
            amount = Math.floor(Number(qty * price * factor));
            break;
          default:
            amount = Math.round(Number(qty * price * factor));
        }
        let isValid = amount <= maxPrice;

        if (!isValid) {
          return i18n.tc("check.chk_limitPrice");
        }
      }
      return true;
    },
    // 金額計算処理
    isValidSumPrice(achieveQuantity, unitPrice, convertedValue) {
      var qty = Number(commonUtil.zen2han(achieveQuantity).replaceAll(",", ""));
      var price = Number(commonUtil.zen2han(unitPrice).replaceAll(",", "").replaceAll("¥", ""));
      var factor = Number(commonUtil.zen2han(convertedValue).replaceAll(",", ""));
      if (!isNaN(qty) && !isNaN(price) && !isNaN(factor)) {
        if (
          !this.rules.price(price) ||
          !this.rules.checkQuantity(qty) ||
          !this.rules.checkConvertedValue(factor)
        ) {
          return "false";
        }
        let amount;
        switch (this.fiveInDiv) {
          case "01":
            // 四捨五入
            amount = Math.round(Number(qty * price * factor));
            break;
          case "02":
            // 切り上げ
            amount = Math.ceil(Number(qty * price * factor));
            break;
          case "03":
            // 切り捨て
            amount = Math.floor(Number(qty * price * factor));
            break;
          default:
            amount = Math.round(Number(qty * price * factor));
        }
        return amount <= 999999999;
      } else {
        return "false";
      }
    },
    inputMasterData(item) {
      if (item.unitPriceSid == void 0) {
        let masterData = this.masterList.filter((value) => {
          return value.invoiceItemSid == item.billingSubject;
        })[0];
        item.officeSales = masterData ? masterData.officeSales : "";
        item.warehouseCd = masterData ? masterData.warehouseCd : "";
      }
    },
    /**
     * 請求契約内容詳細取得
     */
    getBillingContractDeteil() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.clientSid = this.suppliersSelected;
      config.params.businessNo = this.billingBussinessNo;
      config.params.reqComReferenceDate = dateTimeHelper.convertJST();
      let officeSalesJsonList = [{ value: "", text: "" }];
      let warehouseJsonList = [{ value: "", text: "" }];
      let officeSalesSet = new Set();
      let warehouseSet = new Set();
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet("business/bill-contract-detail", config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getBillingContractDeteil() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              const masterList = [];
              jsonData.resIdv.billContract.forEach((row, index) => {
                this.versionSid = row.verSid;
                let unitPriceList = [];
                let unitPrice = this.changeAmountFormat(row.unitPrice);
                let parentUnitPrice = this.changeAmountFormat(row.unitPrice);
                let expireDateFrom = this.isEmpty(row.validFrom) ? "2021/04/01" : row.validFrom;
                let expireDateTo = this.isEmpty(row.validTo) ? "2099/12/31" : row.validTo;
                let warehouseCd = row.gkkyid != null ? row.gkkyid.trimStart().trimEnd() : null;
                let appwarehouseCd = row.gkkyid != null ? row.gkkyid.trimStart().trimEnd() : "";
                let displayUnitPriceListIndex = 0;
                if (row.priceList != null) {
                  let currentDate = dateTimeHelper.convertJstDateTime(new Date());
                  currentDate.setHours(0);
                  currentDate.setMinutes(0);
                  currentDate.setSeconds(0);
                  let expireDateFrom1 = new Date(expireDateFrom);
                  let expireDateTo1 = new Date(expireDateTo);
                  row.priceList.forEach((row, index) => {
                    if (currentDate < expireDateFrom1 || expireDateTo1 < currentDate) {
                      let childExpireDateFrom = new Date(row.validFrom);
                      let childExpireDateTo = new Date(row.validTo);
                      if (currentDate >= childExpireDateFrom && childExpireDateTo >= currentDate) {
                        displayUnitPriceListIndex = index;
                        unitPrice = this.changeAmountFormat(row.unitPrice);
                      }
                    }
                    unitPriceList.push({
                      unitPriceSid: row.unitPriceSid,
                      expireDateFrom: this.isEmpty(row.validFrom) ? "2021/04/01" : row.validFrom,
                      expireDateTo: this.isEmpty(row.validTo) ? "2099/12/31" : row.validTo,
                      unitPrice: this.changeAmountFormat(row.unitPrice),
                      updateDatetime: row.updateDatetime,
                    });
                  });
                } else {
                  for (let i = 0; i < 1; i++) {
                    unitPriceList.push({
                      expireDateFrom: expireDateFrom,
                      expireDateTo: expireDateTo,
                      unitPrice: this.changeAmountFormat(row.unitPrice),
                    });
                  }
                }
                if (row.unitPriceSid != null) {
                  list.push({
                    index: index,
                    versionSid: row.verSid,
                    invoiceItemSid: row.invoiceItemSid,
                    unitPriceSid: row.unitPriceSid,
                    updateDatetime: row.updateDatetime,
                    billingItems: row.gkcode,
                    billingSubject: row.gkkamo,
                    billingSubjectCd: row.gkkamo,
                    unitPriceClassificPrice: row.unitPriceCd,
                    unitPriceClassContents: row.unitPriceDetail,
                    unitPriceType: row.unitPriceGroup,
                    dateOfExpiry:
                      unitPriceList[displayUnitPriceListIndex].expireDateFrom.substr(2) +
                      "\r\n" +
                      unitPriceList[displayUnitPriceListIndex].expireDateTo.substr(2),
                    expireDateFrom: unitPriceList[displayUnitPriceListIndex].expireDateFrom,
                    expireDateTo: unitPriceList[displayUnitPriceListIndex].expireDateTo,
                    expireDateList: unitPriceList,
                    unitPrice: unitPrice,
                    parentUnitPrice: parentUnitPrice,
                    unitPriceUnit: row.unitCd,
                    achieveQuantity: this.changeQuantityFormat(row.act_quantity),
                    convertedValue: row.unitPriceFactor,
                    appOfficeSid: row.appOfficeSid,
                    appWarehouseSid:
                      row.appWarehouseSid != null
                        ? row.appWarehouseSid.trimStart().trimEnd()
                        : null,
                    officeSales: row.gkeico,
                    warehouseCd: appwarehouseCd,
                    ggiyid: warehouseCd,
                    taxClassification: row.taxDiv,
                    calculationClassification: row.calculateDiv,
                    isNewCreation: false,
                    isUpdated: false,
                    hasExpireDateError: false,
                    isValidSumPrice: this.isValidSumPrice(
                      this.changeQuantityFormat(row.act_quantity),
                      unitPrice,
                      row.unitPriceFactor
                    ),
                    displayUnitPriceListIndex: displayUnitPriceListIndex,
                    transferRate:
                      row.giroPercent == null && this.baseCd !== row.gkeico ? 90 : row.giroPercent,
                    registeredTransferRate: row.giroPercent,
                    duplicateBillingItemMsg: "",
                  });
                }
                masterList.push({
                  invoiceItemSid: row.invoiceItemSid,
                  officeSales: row.gkeico,
                  warehouseCd: warehouseCd,
                });
                if (
                  !officeSalesSet.has(row.gkeico) &&
                  !this.isEmpty(row.gkeico) &&
                  !this.isSpaceOnly(row.gkeico)
                ) {
                  officeSalesJsonList.push({ value: row.gkeico, text: row.gkeico });
                }
                if (
                  !warehouseSet.has(warehouseCd) &&
                  !this.isEmpty(warehouseCd) &&
                  !this.isSpaceOnly(warehouseCd)
                ) {
                  warehouseJsonList.push({
                    value: warehouseCd,
                    text: warehouseCd,
                  });
                }
                warehouseSet.add(warehouseCd);
                officeSalesSet.add(row.gkeico);
              });
              this.inputList = list;
              this.masterList = masterList;
              this.tmpList = JSON.parse(JSON.stringify(this.inputList));
              this.officeSalesList = officeSalesJsonList;
              this.warehouseList = warehouseJsonList;
              this.inputList.map((element) => {
                this.addWatcheForDuplicateBillingItem(element);
              });

              resolve(list);
              // エラー時
            } else {
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((resolve) => {
            console.error("getBillingContractDeteil() Resolve", resolve);
            reject(messsageUtil.getMessage("P-999-999_999_E"));
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            this.isInit = false;
          });
      });
    },
    // 営業所プルダウン
    getCustomInfo() {
      const config = this.$httpClient.createGetApiRequestConfig();

      // ★必須パラメータ
      config.params.isMst = "1";
      config.params.relatedPartyDiv = "02";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_RELATED, config)
          .then((response) => {
            // console.debug("getCustomInfo() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [{ text: "", name: "", value: "" }];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                if (row.relatedPartyLanguage.length > 0) {
                  list.push({
                    text: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                    value: row.relatedPartyLanguage[0].relatedPartySid ?? "",
                    name: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                  });
                }
              });
              this.officeSalesList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          });
      });
    },
    // コードマスタ取得
    getMstCode(identifier) {
      // 仮登録
      const lotCnt = getParameter.getCodeMst(identifier);
      return Promise.all([lotCnt])
        .then((result) => {
          // FIXME 変数に修正
          switch (identifier) {
            case "0601":
              this.taxClassificationList = result[0];
              break;
            case "0602":
              this.calculationClassificationList = result[0];
              break;
            default:
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        });
    },
    // 単価マスタ登録API
    postApi(registrationList) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      let isSucess = false;
      let billContract = [];
      this.alertMessage = "";
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_BIL_004;
      body.reqCom.reqComOfficeSid = sessionStorage.getItem("sales_office_sid"); // 取引先SID
      for (let i = 0; i < registrationList.length; i++) {
        let item = registrationList[i];
        let invoiceItemSid = item.isNewCreation ? item.billingSubject : item.invoiceItemSid;
        let unitPriceSid = item.isNewCreation ? "" : item.unitPriceSid;
        let updateDateTime = item.isNewCreation ? null : item.updateDatetime;
        let actQuantity =
          item.calculationClassification == "01"
            ? Number(this.convertAmountToNumber(item.achieveQuantity))
            : null;
        billContract.push({
          verSid: this.versionSid, //バージョンSID
          invoiceItemSid: invoiceItemSid, //請求項目SID
          unitPriceSid: unitPriceSid,
          updateDatetime: updateDateTime,
          businessNo: this.billingBussinessNoSelected, //業務No
          validFrom: item.expireDateFrom,
          validTo: item.expireDateTo,
          clientSid: this.suppliersSelected,
          unitPriceCd: item.unitPriceClassificPrice,
          unitPriceDetail: item.unitPriceClassContents, // 単価内訳
          unitCd: item.unitPriceUnit, //単位CD
          unitPriceGroup: item.unitPriceType, //単価Gr
          unitPrice: Number(item.unitPrice), //単価
          actQuantity: actQuantity, //実績数量
          unitPriceFactor: Number(item.convertedValue), //単価係数
          appOfficeSid: item.appOfficeSid, //売上営業所SID
          appWarehouseSid: item.appWarehouseSid, //売上倉庫SID
          taxDiv: item.taxClassification, //税区分
          calculateDiv: item.calculationClassification, //計算区分
          giroPercent: this.isEmpty(item.transferRate) ? null : Number(item.transferRate), //計算区分
          deleteFlg: item.deleteFlg, //削除フラグ0
        });
      }
      body.reqIdv.billContract = billContract;
      // console.debug("postApi(registrationList) Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost("business/bill-contract", body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("postApi(registrationList) Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              isSucess = true;
              resolve(response);
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            for (let i = 0; i < registrationList.length; i++) {
              registrationList[i].isNewCreation = false;
            }
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            if (isSucess) {
              this.getBillingContractDeteil();
              this.endEvent("btn.btn_complete");
            }
          });
      });
    },
    // 業務マスタ取得ＡＰＩ
    getBusinessMst() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // APIパラメータ
      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所SID
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      config.params.clientSid = this.suppliersSelected;
      // 業務No
      config.params.businessNo = this.billingBussinessNoSelected;
      // マスタ取得区分
      config.params.isMst = "0";

      // console.debug("getBusinessMst() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_BUSINESS, config)
          .then((response) => {
            // console.debug("getBusinessMst() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let fiveInDiv;
              jsonData.resIdv.businesses.forEach((row) => {
                fiveInDiv = row.fiveInDiv;
              });
              this.fiveInDiv = fiveInDiv;
              resolve("業務マスタ取得APIレスポンス", response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    updateOk() {
      this.updateDialogMessage = false;
    },
    isSpaceOnly(value) {
      return value.replaceAll(/\s+/g, "").length == 0;
    },
    parentMethod: function (deleteList, isConfig, target) {
      this.deleteList = deleteList;
      if (isConfig) {
        this.updateDialogMessage = false;
        this.inValidExpireDateList.splice(this.inValidExpireDateList.indexOf(target), 1);
        target.isValidSumPrice = this.isValidSumPrice(
          target.achieveQuantity,
          target.unitPrice,
          target.convertedValue
        );
      }
    },
    changeUnitPriceTxt(target, event, index) {
      this.resetValidation("unitPrice", index);
      new Promise((resolve) => {
        setTimeout(function () {
          target.unitPrice = event;
          resolve();
        }, 1);
      }).then(() => {
        this.parseAmountFormat(target, index);
        if (target.displayUnitPriceListIndex === 0) {
          target.parentUnitPrice = target.unitPrice;
        }
        this.checkPrice(target, true);
      });
    },
    changeUnitPriceClassContentsTxt(target, event) {
      setTimeout(function () {
        target.unitPriceClassContents = event;
      }, 1);
    },
    changeAchieveQuantityTxt(target, event, index) {
      this.resetValidation("achieveQuantity", index);
      new Promise((resolve) => {
        setTimeout(function () {
          target.achieveQuantity = event;
          resolve();
        }, 1);
      }).then(() => {
        this.parseQuantityFormat(target, index);
        this.checkPrice(target, true);
      });
    },
    changeConvertedValueTxt(target, event, index) {
      this.resetValidation("convertedValue", index);
      new Promise((resolve) => {
        setTimeout(function () {
          target.convertedValue = event;
          resolve();
        }, 1);
      }).then(() => {
        this.convertedValChange(target);
        this.checkPrice(target, true);
      });
    },
    changeTransferRate(target, event) {
      new Promise((resolve) => {
        setTimeout(function () {
          target.transferRate = event;
          resolve();
        }, 1);
      }).then(() => {
        if (event !== "") {
          let num = Number(commonUtil.zen2han(event));
          target.transferRate = isNaN(num) ? event : num;
          event = isNaN(num) ? event : num;
        }
      });
    },
    setBaseCd() {
      this.baseCd = this.loginUserOffice.length !== 0 ? this.loginUserOffice[0].baseCd : "";
    },
    /**
     * バリデーション結果をリセットする
     */
    resetValidation(target, index) {
      switch (target) {
        case "unitPrice":
          this.unitPriceResetValidation(target, index);
          break;
        case "achieveQuantity":
          this.achieveQuantityResetValidation(target, index);
          break;
        case "convertedValue":
          this.convertedValueResetValidation(target, index);
          break;
        default:
          break;
      }
    },
    /**
     * 単価のバリデーション結果ををリセットする
     */
    unitPriceResetValidation(target, index) {
      eval("this.$refs.achieveQuantity" + index + ".resetValidation()");
      eval("this.$refs.convertedValue" + index + ".resetValidation()");
    },
    /**
     * 固定数量のバリデーション結果ををリセットする
     */
    achieveQuantityResetValidation(target, index) {
      eval("this.$refs.unitPrice" + index + ".resetValidation()");
      eval("this.$refs.convertedValue" + index + ".resetValidation()");
    },
    /**
     * 単価係数のバリデーション結果ををリセットする
     */
    convertedValueResetValidation(target, index) {
      eval("this.$refs.unitPrice" + index + ".resetValidation()");
      eval("this.$refs.achieveQuantity" + index + ".resetValidation()");
    },
    /**
     * キー項目の重複チェックを行う
     */
    isduplicateBillingItem(identifier, item, newVal, oldVal) {
      // 値の変更後にエラーになる行を取得する
      let targets = this.getDuplicateBillingItem(identifier, item, newVal);
      // 値の変更前にエラーになっていた行を取得する
      let refreshTarget = this.getDuplicateBillingItem(identifier, item, oldVal);

      // 値を変更することで重複がなくなる場合は、自身の行のエラーを解除
      let validResult = 1 === refreshTarget.length;
      if (validResult) {
        let index = this.inputList.indexOf(refreshTarget[0]);
        if (index !== -1) {
          this.inputList[index].duplicateBillingItemMsg = "";
        }
      }

      // 値を変更することで重複する場合は、対象の行をエラーにする
      let inValidResult = 1 !== targets.length;
      if (!inValidResult) {
        item.duplicateBillingItemMsg = "";
      } else {
        targets.map((element) => {
          element.duplicateBillingItemMsg = i18n.tc("check.chk_duplicate_billingItem");
        });
        return i18n.tc("check.chk_duplicate_billingItem");
      }
      return inValidResult;
    },
    /**
     * 請求項目のキー項目が重複しているデータを取得する
     */
    getDuplicateBillingItem(identifier, item, val) {
      let targets = [];
      switch (identifier) {
        case "billingItems":
          targets = this.inputList.filter((element) => {
            return (
              element.billingItems === val &&
              element.billingSubjectCd === item.billingSubjectCd &&
              element.unitPriceClassificPrice === item.unitPriceClassificPrice &&
              element.unitPriceType === item.unitPriceType &&
              element.unitPriceClassContents === item.unitPriceClassContents
            );
          });
          break;
        case "billingSubject":
          targets = this.inputList.filter((element) => {
            return (
              element.billingItems === item.billingItems &&
              (element.isNewCreation
                ? element.billingSubject === val
                : element.invoiceItemSid === val) &&
              element.unitPriceClassificPrice === item.unitPriceClassificPrice &&
              element.unitPriceType === item.unitPriceType &&
              element.unitPriceClassContents === item.unitPriceClassContents
            );
          });
          break;
        case "unitPriceClassificPrice":
          targets = this.inputList.filter((element) => {
            return (
              element.billingItems === item.billingItems &&
              element.billingSubjectCd === item.billingSubjectCd &&
              element.unitPriceClassificPrice === val &&
              element.unitPriceType === item.unitPriceType &&
              element.unitPriceClassContents === item.unitPriceClassContents
            );
          });
          break;
        case "unitPriceType":
          targets = this.inputList.filter((element) => {
            return (
              element.billingItems === item.billingItems &&
              element.billingSubjectCd === item.billingSubjectCd &&
              element.unitPriceClassificPrice === item.unitPriceClassificPrice &&
              element.unitPriceType === val &&
              element.unitPriceClassContents === item.unitPriceClassContents
            );
          });
          break;
        case "unitPriceClassContents":
          targets = this.inputList.filter((element) => {
            return (
              element.billingItems === item.billingItems &&
              element.billingSubjectCd === item.billingSubjectCd &&
              element.unitPriceClassificPrice === item.unitPriceClassificPrice &&
              element.unitPriceType === item.unitPriceType &&
              element.unitPriceClassContents === val
            );
          });
          break;
        default:
          break;
      }
      return targets;
    },
    /**
     * 行の重複監視を動的に設定する
     */
    addWatcheForDuplicateBillingItem(element) {
      this.$watch(
        () => element.billingItems,
        (newVal, oldVal) => {
          this.isduplicateBillingItem("billingItems", element, newVal, oldVal);
        }
      );
      this.$watch(
        () => element.billingSubject,
        (newVal, oldVal) => {
          this.isduplicateBillingItem("billingSubject", element, newVal, oldVal);
        }
      );
      this.$watch(
        () => element.unitPriceClassificPrice,
        (newVal, oldVal) => {
          this.isduplicateBillingItem("unitPriceClassificPrice", element, newVal, oldVal);
        }
      );
      this.$watch(
        () => element.unitPriceClassContents,
        (newVal, oldVal) => {
          this.isduplicateBillingItem("unitPriceClassContents", element, newVal, oldVal);
        }
      );
      this.$watch(
        () => element.unitPriceType,
        (newVal, oldVal) => {
          this.isduplicateBillingItem("unitPriceType", element, newVal, oldVal);
        }
      );
    },
    /**
     * 重複エラーがあるか判定する
     */
    isDuplicateBillingItemError() {
      let result = this.inputList.filter((element) => {
        return element.duplicateBillingItemMsg != "";
      });
      return result.length !== 0;
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    loadingCounter(val) {
      if (this.isInit && val == 1) {
        this.$refs.main.$el.style.display = "none";
      } else {
        setTimeout(function () {
          document.getElementById("main").style.display = "block";
        }, 100);
      }
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.from_to {
  font-weight: bold;
  // padding-top: 8px;
  // margin-left: 0;
  // margin-right: 0;
}

.toatlNum {
  margin-left: 10px;
}

.v-data-table {
  white-space: pre-line;
}

.align-left {
  text-align: left;
}

// テーブルフッターの高さ調整
// .minheight {
//   margin-bottom: 0px;
// }
</style>
