<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <Loading />
    <!-- 検索項目 -->
    <v-container fluid>
      <!--ボタン領域-->
      <v-row style="margin-left: 3px">
        <div class="search-row-exeBtn pt-2">
          <!-- 請求内容 -->
          <div style="float: left">
            <!-- 閉じるボタン-->
            <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_close')">
              {{ $t("btn.btn_close") }}
            </v-btn>
          </div>
          <div style="float: right">
            <!-- 行追加ボタン-->
            <v-btn color="primary" class="api-btn" @click="addItem()">
              {{ $t("btn.btn_rowsAdd") }}
            </v-btn>
            <!-- 設定ボタン -->
            <v-btn
              color="primary"
              id="btn-content-config"
              class="other-btn"
              @click="viewConfirmDialog('btn_config')"
              >{{ $t("btn.btn_config") }}</v-btn
            >
          </div>
        </div>
      </v-row>
    </v-container>

    <!-- データテーブル -->
    <v-form ref="editedList" lazy-validation>
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="selectedExpireDateListTmp"
          disable-filtering
          disable-sort
          disable-pagination
          height="250px"
          :hide-default-footer="true"
        >
          <template v-slot:[`item.expireDateFrom`]="{ item, index }">
            <div style="float: left">
              <v-text-field
                dense
                outlined
                v-model="expireDateFromList[index]"
                class="text-box"
                :ref="'expireDateFrom' + index"
                :id="'expireDateFrom' + index"
                maxLength="10"
                :rules="[
                  rules.required(
                    index,
                    expireDateFromList,
                    expireDateToList,
                    unitPriceList,
                    expireDateFromList[index]
                  ),
                  rules.yyyymmdd,
                  checkExpireDateRange('from', index),
                ]"
                @change="changeDateFrom(expireDateFromList[index], index)"
              />
            </div>
            <div style="float: left" class="calender-component">
              <v-menu
                v-model="dateMenuFrom[index]"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="expireDateFromCalList[index]"
                  @input="dateMenuFrom[index] = false"
                >
                </v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevFromDate(index)"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextFromDate(index)"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.expireDateTo`]="{ item, index }">
            <!-- 有効期限TO-->
            <div style="float: left">
              <v-text-field
                dense
                outlined
                v-model="expireDateToList[index]"
                maxLength="10"
                class="text-box"
                full-width
                :ref="'expireDateTo' + index"
                :id="'expireDateTo' + index"
                :rules="[
                  rules.required(
                    index,
                    expireDateFromList,
                    expireDateToList,
                    unitPriceList,
                    expireDateToList[index]
                  ),
                  rules.yyyymmdd,
                  checkExpireDateRange('to', index),
                ]"
                @change="changeDateTo(expireDateToList[index], index)"
              />
            </div>
            <div style="float: left" class="calender-component">
              <v-menu
                v-model="dateMenuTo[index]"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="expireDateToCalList[index]"
                  @input="dateMenuTo[index] = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevToDate(index)"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextToDate(index)" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.unitPrice`]="{ item, index }">
            <!-- 単価 -->
            <v-text-field
              dense
              outlined
              v-model="unitPriceList[index]"
              maxLength="12"
              class="input-number"
              :rules="[
                rules.required(
                  index,
                  expireDateFromList,
                  expireDateToList,
                  unitPriceList,
                  unitPriceList[index]
                ),
                rules.isNumber,
                rules.isZero,
                rules.priceFormat,
                checkPrice,
              ]"
              :ref="'unitPrice' + index"
              @change="parseAmountFormat(unitPriceList[index], index)"
            />
          </template>
          <template v-slot:[`item.deleteData`]="{ item, index }">
            <v-btn small @click="deleteItem(item, index)" text v-if="index != 0">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-form>

    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :message="confirmDialog.message"
      :okAction="confirmDialog.okAction"
      :redMessage="confirmDialog.redMessage"
      :screenFlag="confirmDialog.screenFlag"
      :changeFlag="confirmDialog.changeFlag"
    />

    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :redMessage="infoDialog.redMessage"
      :screenFlag="infoDialog.screenFlag"
      :changeFlag="infoDialog.changeFlag"
    />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import SimpleDialog from "@/components/SimpleDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import ConfirmDialog from "@/components/ConfirmDialog";
import { i18n } from "../../lang/lang.js";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
export default {
  name: "BillingEditExpireDate",
  components: {
    Loading,
    ConfirmDialog,
    SimpleDialog,
  },
  // 親画面から渡される値
  props: {
    selectedIndex: { type: Number, default: 0 },
    selectedExpireDateList: { type: Array, default: null },
    viewDataList: { type: Array, default: null },
    isOpenDetailDialog: { type: Boolean, default: false },
    _deleteList: { type: Array, default: null },
    fiveInDiv: { type: String, default: "" },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    expireDateFromList: [],
    expireDateToList: [],
    expireDateToCalList: [],
    expireDateFromCalList: [],
    unitPriceList: [],
    dateMenuFrom: [],
    dateMenuTo: [],
    selectedExpireDateListTmp: [],
    viewDataTmp: {},
    deleteListTmp: [],
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    defaultItem: {
      expireDateFrom: null,
      expireDateTo: null,
      unitPrice: null,
      isNewCreation: true,
    },
    // ヘッダ
    headerItems: [
      // 有効期限FROM
      {
        text: i18n.tc("label.lbl_validStartDateAndTime"),
        value: "expireDateFrom",
        width: "30%",
        align: "left",
        sortable: false,
      },
      // 有効期限TO
      {
        text: i18n.tc("label.lbl_validEndDateAndTime"),
        value: "expireDateTo",
        width: "30%",
        align: "left",
        sortable: false,
      },
      // 単価
      {
        text: i18n.tc("label.lbl_unitPrice"),
        value: "unitPrice",
        width: "25%",
        align: "left",
        sortable: false,
      },
      // 削除
      { text: i18n.tc("btn.btn_delete"), value: "deleteData", width: "6%", align: "center" },
    ],
    // 更新リスト
    updateList: [],
    rules: {
      required: function (index, fromList, toList, priceList, value) {
        if (index == 0 && !value) {
          return i18n.tc("check.chk_input");
        }
        if (0 < index) {
          if (!value) {
            let from = fromList[index];
            let to = toList[index];
            let price = priceList[index];
            if (!(!from && !to && !price)) {
              return i18n.tc("check.chk_input");
            }
          }
        }
        return true;
      },
      isNumber: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "").replaceAll("¥", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      yyyymmdd: function (value) {
        if (!value) {
          return true;
        }
        if (
          !(
            dateTimeHelper.validDate(commonUtil.zen2han(value), "/") && dateTimeHelper.isDate(value)
          )
        ) {
          return i18n.tc("check.chk_inputDate");
        }
        return true;
      },
      priceFormat: function (value) {
        if (!value) {
          return true;
        }
        let isValid;
        if (value !== undefined && value !== null && value !== "") {
          value = String(value);
          if (
            (value.includes("¥") && 0 < value.indexOf("¥")) ||
            value.indexOf("¥") != value.lastIndexOf("¥")
          ) {
            return i18n.tc("check.chk_inputNumber_9");
          }
          value = value.replaceAll(",", "").replaceAll("¥", "");
        }
        const regex = /^([1-9])(\d{0,8})$/;
        if (regex.test(Number(value))) {
          isValid = true;
        } else {
          isValid = false;
        }
        if (!isValid) {
          return i18n.tc("check.chk_inputNumber_9");
        }
        return true;
      },
      isZero: function (value) {
        let strVal = String(value);
        let enInclude = strVal.includes("¥", 0);
        if (enInclude) {
          strVal = strVal.slice(1);
        }
        if (strVal == "0") {
          return i18n.tc("check.chk_inputThanZero");
        }
        return true;
      },
    },
  }),

  methods: {
    /**
     * カンマ付きの数字に変換する
     */
    parseAmountFormat(value, index) {
      if (!value) {
        return value;
      }
      let unitPrice = String(value);
      unitPrice = commonUtil.zen2han(unitPrice);
      let enInclude = unitPrice.includes("¥", 0);
      if (enInclude) {
        unitPrice = unitPrice.slice(1);
      }
      if (isNaN(Number(unitPrice.replaceAll(",", "")))) {
        return;
      }
      unitPrice = unitPrice.replaceAll(",", "");
      const isDecimal = 1 < value.split(".").length;
      unitPrice = unitPrice.split(".")[0];
      let decimal;
      if (isDecimal) {
        decimal = value.split(".")[1];
      }
      let delimiter = ",";
      let len = unitPrice.length;
      let delimiterPoint = 3;
      if (len >= delimiterPoint) {
        let numOfDeliimiter =
          len % delimiterPoint == 0 ? len / delimiterPoint - 1 : len / delimiterPoint;

        for (let i = 1; i <= numOfDeliimiter; i++) {
          let index = len - delimiterPoint * i;
          let previousTxt = unitPrice.slice(0, index);
          let behindTxt = unitPrice.slice(index);
          unitPrice = previousTxt + delimiter + behindTxt;
        }
      }
      unitPrice = this.strIns(unitPrice, 0, "¥");
      if (isDecimal && decimal != 0) {
        unitPrice = this.strIns(unitPrice, unitPrice.length, "." + decimal);
      }
      this.$set(this.unitPriceList, index, unitPrice);
    },
    /**
     * 指定の位置に文字列を挿入する
     */
    strIns(str, idx, val) {
      return str.slice(0, idx) + val + str.slice(idx);
    },
    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action;
      switch (button) {
        case "btn_config":
          isValidationRequired = true;
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_config")]);
          action = this.config;
          break;
        case "btn_close":
          isValidationRequired = false;
          message = messsageUtil.getMessage("P-COM-001_004_W");
          action = this.close;
          break;
        default:
          isValidationRequired = true;
          message = "";
      }
      if (isValidationRequired) {
        let isValid = this.$refs.editedList.validate();
        if (!isValid) {
          return;
        }
      }
      this.confirmDialog.message = message;
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = action;
    },
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.expireDateFromList = [];
      this.expireDateToList = [];
      this.expireDateFromCalList = [];
      this.expireDateToCalList = [];
      this.unitPriceList = [];
      this.deleteListTmp = [];
      this.selectedExpireDateListTmp = JSON.parse(JSON.stringify(this.selectedExpireDateList));
      for (let i = 0; i < this.selectedExpireDateList.length; i++) {
        let fromDate;
        let toDate;
        if (
          !this.selectedExpireDateList[i].expireDateFrom ||
          !this.selectedExpireDateList[i].expireDateTo
        ) {
          fromDate = "";
          toDate = "";
        } else {
          fromDate = dateTimeHelper.dateCalc(
            new Date(this.selectedExpireDateList[i].expireDateFrom).setHours(9)
          );
          toDate = dateTimeHelper.dateCalc(
            new Date(this.selectedExpireDateList[i].expireDateTo).setHours(9)
          );
        }
        this.expireDateFromList.push(this.formatDate(fromDate));
        this.expireDateToList.push(this.formatDate(toDate));
        this.expireDateFromCalList.push(fromDate);
        this.expireDateToCalList.push(toDate);
        this.unitPriceList.push(this.selectedExpireDateList[i].unitPrice);
      }
      this.viewDataTmp = JSON.parse(JSON.stringify(this.viewDataList[this.selectedIndex]));
      let form = this.$refs.editedList;
      setTimeout(function () {
        form.validate();
      }, 10);
    },

    /**
     * 閉じる処理
     */
    close() {
      this.$emit("update:isOpenDetailDialog", false);
    },

    config() {
      if (this.$refs.editedList.validate()) {
        this.viewDataList[this.selectedIndex].hasExpireDateError = false;
        let availableDate = false;
        let currentDate = dateTimeHelper.convertJstDateTime(new Date());
        currentDate.setHours(0);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        // TODO 登録処理
        for (let i = 0; i < this.selectedExpireDateListTmp.length; i++) {
          this.selectedExpireDateListTmp[i].expireDateFrom = this.expireDateFromList[i];
          this.selectedExpireDateListTmp[i].expireDateTo = this.expireDateToList[i];
          this.selectedExpireDateListTmp[i].unitPrice = this.unitPriceList[i];
          let childExpireDateFrom = new Date(this.expireDateFromList[i]);
          let childExpireDateTo = new Date(this.expireDateToList[i]);
          if (currentDate >= childExpireDateFrom && childExpireDateTo >= currentDate) {
            this.viewDataList[this.selectedIndex].displayUnitPriceListIndex = i;
            availableDate = true;
          }
        }
        // いずれの日時も期限外の場合先頭行を表示
        if (!availableDate) {
          this.viewDataList[this.selectedIndex].displayUnitPriceListIndex = 0;
        }
        let result = this.selectedExpireDateListTmp.filter((value) => {
          return (
            null != value.expireDateFrom && null != value.expireDateTo && null != value.unitPrice
          );
        });
        let displayUnitPriceListIndex =
          this.viewDataList[this.selectedIndex].displayUnitPriceListIndex;
        let selectedExpireDateList = JSON.parse(JSON.stringify(result));
        this.viewDataList[this.selectedIndex].dateOfExpiry =
          selectedExpireDateList[displayUnitPriceListIndex].expireDateFrom.substr(2) +
          "\r\n" +
          selectedExpireDateList[displayUnitPriceListIndex].expireDateTo.substr(2);
        this.viewDataList[this.selectedIndex].unitPrice =
          selectedExpireDateList[displayUnitPriceListIndex].unitPrice;
        this.viewDataList[this.selectedIndex].expireDateList = selectedExpireDateList;
        this.viewDataList[this.selectedIndex].expireDateFrom =
          selectedExpireDateList[displayUnitPriceListIndex].expireDateFrom;
        this.viewDataList[this.selectedIndex].expireDateTo =
          selectedExpireDateList[displayUnitPriceListIndex].expireDateTo;
        this.viewDataList[this.selectedIndex].parentUnitPrice = selectedExpireDateList[0].unitPrice;
        let deleteList = [...this._deleteList, ...this.deleteListTmp];
        this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
          i18n.tc("btn.btn_config"),
        ]);
        this.infoDialog.title = "結果";
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.itemsMstFlg = true;
        this.$emit("child-event", deleteList, true, this.viewDataList[this.selectedIndex]);
        this.close();
      }
    },
    /**
     * 有効期限Fromを－１日します。
     */
    prevFromDate(index) {
      let date = new Date(this.expireDateFromList[index]);
      date.setHours(9);
      let updatedDate = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      this.$set(this.expireDateFromList, index, this.formatDate(updatedDate));
      this.$set(this.expireDateFromCalList, index, updatedDate);
    },

    /**
     * 有効期限Fromを＋１日します。
     */
    nextFromDate(index) {
      let date = new Date(this.expireDateFromList[index]);
      date.setHours(9);
      let updatedDate = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      this.$set(this.expireDateFromList, index, this.formatDate(updatedDate));
      this.$set(this.expireDateFromCalList, index, updatedDate);
    },

    /**
     * 有効期限Toを－１日します。
     */
    prevToDate(index) {
      let date = new Date(this.expireDateToList[index]);
      date.setHours(9);
      let updatedDate = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      this.$set(this.expireDateToList, index, this.formatDate(updatedDate));
      this.$set(this.expireDateToCalList, index, updatedDate);
    },

    /**
     * 有効期限Toを＋１日します。
     */
    nextToDate(index) {
      let date = new Date(this.expireDateToList[index]);
      date.setHours(9);
      let updatedDate = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      this.$set(this.expireDateToList, index, this.formatDate(updatedDate));
      this.$set(this.expireDateToCalList, index, updatedDate);
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val, index) {
      if (!val) {
        this.$set(this.expireDateFromCalList, index, null);
        return;
      }
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.$set(this.expireDateFromList, index, strDt);
        this.$set(
          this.expireDateFromCalList,
          index,
          dateTimeHelper.dateCalc(new Date(strDt).setHours(9))
        );
      }
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val, index) {
      if (!val) {
        this.$set(this.expireDateToCalList, index, null);
        return;
      }
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.$set(this.expireDateToList, index, strDt);
        this.$set(
          this.expireDateToCalList,
          index,
          dateTimeHelper.dateCalc(new Date(strDt).setHours(9))
        );
      }
    },

    /**
     * 有効期限の入力範囲チェック
     */
    checkExpireDateRange(target, index) {
      if (!this.expireDateFromList[index] || !this.expireDateToList[index]) {
        return true;
      }
      let inputFrom = new Date(this.expireDateFromList[index]);
      let inputTo = new Date(this.expireDateToList[index]);
      let msg;
      // Fromに対してToの日付が過去日かをチェック
      if (inputTo <= inputFrom) {
        switch (target) {
          case "from":
            msg = i18n.tc("check.chk_inputPastDate");
            break;
          case "to":
            msg = i18n.tc("check.chk_inputFutureDate");
            break;
          default:
        }
        return msg;
      }
      // 異なる期に重複する期間が設定されているかをチェック
      const len = this.expireDateFromList.length;
      for (let i = 0; i < len; i++) {
        if (i == index) {
          continue;
        }
        if (!this.expireDateFromList[i] || !this.expireDateToList[i]) {
          continue;
        }
        let from = new Date(this.expireDateFromList[i]);
        let to = new Date(this.expireDateToList[i]);
        if (target === "from") {
          if ((inputFrom >= from && inputFrom <= to) || (inputFrom < from && inputTo > to)) {
            return i18n.tc("check.chk_period_duplicate");
          }
        } else {
          if (inputTo >= from && inputTo <= to) {
            return i18n.tc("check.chk_period_duplicate");
          }
        }
      }
      return true;
    },
    // 行追加
    addItem() {
      this.selectedExpireDateListTmp.push(Object.assign({}, this.defaultItem));
      this.expireDateFromList.push(null);
      this.expireDateFromCalList.push(null);
      this.expireDateToList.push(null);
      this.expireDateToCalList.push(null);
      this.unitPriceList.push(null);
      this.scrollToEnd();
    },
    /**
     * 行削除処理
     */
    deleteItem(item, index) {
      if (0 < index) {
        this.selectedExpireDateListTmp.splice(index, 1);
        this.expireDateFromList.splice(index, 1);
        this.expireDateFromCalList.splice(index, 1);
        this.expireDateToList.splice(index, 1);
        this.expireDateToCalList.splice(index, 1);
        this.unitPriceList.splice(index, 1);
        if (!item.isNewCreation) {
          let deleteItemBase = this.viewDataTmp;
          let deleteItem = Object.assign({}, deleteItemBase);
          deleteItem.unitPriceSid = deleteItemBase.expireDateList[index].unitPriceSid;
          deleteItem.expireDateList = [];
          deleteItem.updateDatetime = deleteItemBase.expireDateList[index].updateDatetime;
          deleteItem.expireDateFrom = deleteItemBase.expireDateList[index].expireDateFrom;
          deleteItem.expireDateTo = deleteItemBase.expireDateList[index].expireDateTo;
          deleteItem.unitPrice = this.convertAmountToNumber(
            deleteItemBase.expireDateList[index].unitPrice
          );
          deleteItem.deleteFlg = "1";
          this.deleteListTmp.push(deleteItem);
          this.viewDataTmp.expireDateList.splice(index, 1);
        }
        let currentDispIndex = this.viewDataList[this.selectedIndex].displayUnitPriceListIndex;
        if (currentDispIndex == index) {
          this.viewDataList[this.selectedIndex].displayUnitPriceListIndex = 0;
        } else if (currentDispIndex != 0 && currentDispIndex > index) {
          this.viewDataList[this.selectedIndex].displayUnitPriceListIndex = currentDispIndex - 1;
        }
      }
    },
    /**
     * 最終行にスクロールを移動する
     */
    scrollToEnd() {
      let table = this.$refs.editedList.$el.childNodes[0].childNodes[0].childNodes[0];
      // 行追加後にスクロールバーの高さが変更されるまで待機した後に処理実行
      setTimeout(function () {
        table.scrollTo(0, table.scrollHeight);
      }, 100);
    },
    /**
     * 金額を整数に変換する
     */
    convertAmountToNumber(value) {
      if (value == void 0 || value == null) {
        return value;
      }
      value = String(value);
      let enInclude = value.includes("¥", 0);
      if (enInclude) {
        value = value.slice(1);
      }
      return value.replaceAll(",", "");
    },
    // 金額計算処理
    checkPrice(value) {
      var qty = commonUtil
        .zen2han(this.viewDataList[this.selectedIndex].achieveQuantity)
        .replaceAll(",", "");
      var price = commonUtil.zen2han(value).replaceAll(",", "").replaceAll("¥", "");
      var factor = commonUtil
        .zen2han(this.viewDataList[this.selectedIndex].convertedValue)
        .replaceAll(",", "");
      if (!isNaN(Number(qty)) && !isNaN(Number(price)) && !isNaN(Number(factor))) {
        let amount;
        switch (this.fiveInDiv) {
          case "01":
            // 四捨五入
            amount = Math.round(Number(qty * price * factor));
            break;
          case "02":
            // 切り上げ
            amount = Math.ceil(Number(qty * price * factor));
            break;
          case "03":
            // 切り捨て
            amount = Math.floor(Number(qty * price * factor));
            break;
          default:
            amount = Math.round(Number(qty * price * factor));
        }
        let isValid = amount <= 999999999;
        if (!isValid) {
          return i18n.tc("check.chk_limitPrice");
        }
      }
      return true;
    },
    reload() {},
  },
  computed: {},
  watch: {
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    expireDateFromCalList(val) {
      for (let i = 0; i < val.length; i++) {
        this.$set(this.expireDateFromList, i, this.formatDate(val[i]));
      }
    },
    expireDateToCalList(val) {
      for (let i = 0; i < val.length; i++) {
        this.$set(this.expireDateToList, i, this.formatDate(val[i]));
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.text-popBtn {
  padding-bottom: 30px;
}

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

// ツールチップ ...(省略)設定
.text-overflow {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-row {
  pointer-events: none;
}

.search-autocomplete-product {
  width: 24rem;
}

.calender-component {
  margin: 16px 0 0 0;
  height: 3rem;
}
</style>
